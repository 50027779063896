import React, { useState, useContext } from 'react';
import { ThemeContext } from '../../App';
import CountryFlag from 'react-country-flag';
import { countryMapping } from '../Country/country';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import traductions from '../Traduction/traduction'; // Adjust path as necessary

const Post = ({ post, setData, language }) => {
  const theme = useContext(ThemeContext);
  const [expanded, setExpanded] = useState(false);

  const handleExpand = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
  };

  // Function to get the countryCode based on the country name
  const getCountryCode = (country) => {
    return countryMapping[country] || ''; // Return empty string if country code not found
  };

  // Function to calculate the time difference and return in minutes or hours
  const calculateTimeDifference = (createdAt) => {
    const createdAtMoment = moment.tz(createdAt, 'YYYY-MM-DD HH:mm:ss ZZ', 'GMT');
    const currentMoment = moment();
    const timeDifferenceInMinutes = currentMoment.diff(createdAtMoment, 'minutes');

    if (timeDifferenceInMinutes >= 60) {
      const timeDifferenceInHours = currentMoment.diff(createdAtMoment, 'hours');
      return `${timeDifferenceInHours} hour${timeDifferenceInHours !== 1 ? 's' : ''} ago`;
    }

    return `${timeDifferenceInMinutes} min${timeDifferenceInMinutes !== 1 ? 's' : ''} ago`;
  };

  // Translation function
  const translate = (key) => {
    if (traductions[key] && traductions[key][language]) {
      return traductions[key][language];
    } else {
      console.warn(`Translation for '${key}' not found for language '${language}'`);
      return key; // Fallback to the key itself if not found
    }
  };

  // Extracting the URL from the evidence field
  let evidenceUrl = null;
  if (post.FactCheck?.evidence && post.FactCheck.evidence.length > 0) {
    for (const item of post.FactCheck.evidence) {
      if (item.startsWith('http')) {
        evidenceUrl = item;
        break;
      }
    }
  }

  return (
    <div
      onClick={handleExpand}
      className={`bg-[#fff] border border-[#031f2d] flex flex-col px-7 py-4 rounded-lg mx-4 mb-2 gap-2 cursor-pointer`}
    >
      <div className="flex items-center justify-start space-x-2">
        {post.country && (
          <div className="flex items-center space-x-2">
            <p className={`text-sm flex items-center ${theme === 'dark' ? 'bg-dark-blue' : 'bg-light-blue'} rounded-full overflow-hidden`} style={{ width: '30px', height: '30px', border: '2px solid #ccc' }}>
              <CountryFlag countryCode={getCountryCode(post.country)} svg countryCodeOnly={true} aria-label='Flag' style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }} />
            </p>
            <p className={`text-sm flex items-center rounded-full ${theme === 'dark' ? 'bg-dark-blue' : 'text-gray-400'} px-1 rounded-full`}>
              {translate('source')}: {post.source}, {post.country}
            </p>
          </div>
        )}
        <div className="flex-grow"></div>
        <div className="flex justify-between items-center mx-4 mb-2">
          {post.FactCheck?.factuality != null && (
            <p className={`text-sm flex items-center bg-[#031f2d] rounded-full px-1 ml-2 rounded-full ${post.FactCheck.factuality ? 'bg-green-500' : 'bg-red-500'}`}style={{ marginRight: '20px' }}>
              {post.FactCheck.factuality.toString() === 'true' ? 'Verified' : post.FactCheck.factuality.toString() === 'false' ? 'Warning' : 'Non-factual'}
            </p>
          )}
          {post.FactCheck?.factuality == null && (
            <p className={`text-sm flex items-center bg-[#031f2d] rounded-full px-1 ml-2 rounded-full bg-[#efc9d9]`}style={{ marginRight: '20px' }} >
              Non-factual
            </p>
          )}
        </div>
      </div>
      <div className="grid grid-cols-[98%_2%] justify-center">
        <h1 className="text-lg md:text-xl text-gray-900 leading-snug mb-1 font-semibold">{post.title}</h1>
      </div>
      {post.FactCheck?.correction && post.FactCheck.factuality === false && (
        <p className="text-base text-gray-800 italic">
          <span style={{ textDecoration: 'underline' }}>{translate('correction')}:</span> {post.FactCheck.correction.toString()}
        </p>
      )}
      <div className="flex justify-between items-center mx-4 mb-2">
        <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
        <p className={`text-sm flex items-center rounded-full ${theme === 'dark' ? 'bg-dark-blue' : 'text-gray-400'} px-1 rounded-full`}>
          <FontAwesomeIcon icon={faClock} style={{ marginRight: '4px' }} />
          {calculateTimeDifference(post.createdAt)}
        </p>
      </div>
      {expanded && (
  <div>
    <div>
      {post.FactCheck?.error && post.FactCheck.factuality === false && (
        <>
          <p className="text-base text-gray-800">
            <span style={{ textDecoration: 'underline' }}>{translate('error')}:</span> {post.FactCheck.error.toString()}
          </p>
          <br />
        </>
      )}
      {post.FactCheck?.reasoning && post.FactCheck.factuality === false && (
        <>
          <p className="text-base text-gray-800">
            <span style={{ textDecoration: 'underline' }}>{translate('reasoning')}:</span> {post.FactCheck.reasoning.toString()}
          </p>
          <br />
        </>
      )}
      {evidenceUrl && post.FactCheck.factuality === false && (  
        <>
          <p className="text-base text-gray-800 italic">
            <span style={{ textDecoration: 'underline' }}>{translate('evidence')}:</span> <a href={evidenceUrl}>{evidenceUrl}</a>
          </p>
          <br />
        </>
      )}
      <p className="text-base text-gray-800">
        <span style={{ textDecoration: 'underline' }}>{translate('original article')}:</span> {post.description} [...]
      </p>
            <br />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {post.tag2 && post.tag2 !== 'All' && (
                <p className={`text-sm text-white flex items-center bg-[#031f2d] rounded-full${theme === 'dark' ? 'bg-white' : 'bg-white'} px-1 rounded-full ml-2`}>{post.tag2}</p>
              )}
              <br />
              <br />
            </div>
            <p className="text-base">
              <a
                href={post.link}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'none',
                  color: '#031f2d', // Text color
                  display: 'inline-block',
                  border: '1px solid #7ed957', // Border color
                  borderRadius: '20px', // Rounded border
                  overflow: 'hidden', // Ensure border is applied properly
                }}
              >
                <span style={{ padding: '10px 20px', display: 'block' }}>{translate('read original article')}</span>
              </a>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Post;
