const traductions = {
    "source": {
        "English": "Source",
        "French": "Source",
        "Spanish": "Fuente",
        "Hindi": "स्रोत",
        "Chinese": "源",
        "Japanese": "ソース",
        "Arabic": "مصدر",
        "Hebrew": "מקור"
    },
    "verified": {
        "English": "Verified",
        "French": "Vérifié",
        "Spanish": "Verificado",
        "Hindi": "सत्यापित",
        "Chinese": "已验证",
        "Japanese": "確認済み",
        "Arabic": "تم التحقق منه",
        "Hebrew": "מאומת"
    },
    "warning": {
        "English": "Warning",
        "French": "Attention",
        "Spanish": "Advertencia",
        "Hindi": "चेतावनी",
        "Chinese": "警告",
        "Japanese": "警告",
        "Arabic": "تحذير",
        "Hebrew": "אזהרה"
    },
    "correction": {
        "English": "Correction",
        "French": "Correction",
        "Spanish": "Corrección",
        "Hindi": "सुधार",
        "Chinese": "更正",
        "Japanese": "訂正",
        "Arabic": "تصحيح",
        "Hebrew": "תיקון"
    },
    "error": {
        "English": "Error",
        "French": "Faute",
        "Spanish": "Error",
        "Hindi": "त्रुटि",
        "Chinese": "错误",
        "Japanese": "エラー",
        "Arabic": "خطأ",
        "Hebrew": "שגיאה"
    },
    "reasoning": {
        "English": "Reasoning",
        "French": "Raisonnement",
        "Spanish": "Razonamiento",
        "Hindi": "तर्क",
        "Chinese": "推理",
        "Japanese": "論理",
        "Arabic": "استدلال",
        "Hebrew": "היגיון"
    },
    "evidence": {
        "English": "Same topic, different facts",
        "French": "Même sujet, différents faits",
        "Spanish": "Mismo tema, hechos diferentes",
        "Hindi": "एक ही विषय, भिन्न तथ्य",
        "Chinese": "同一主题，不同事实",
        "Japanese": "同じテーマ、異なる事実",
        "Arabic": "نفس الموضوع، حقائق مختلفة",
        "Hebrew": "אותו נושא, עובדות שונות"
    },    
    "original article": {
        "English": "Original Article",
        "French": "Article original",
        "Spanish": "Artículo original",
        "Hindi": "मौलिक लेख",
        "Chinese": "原始文章",
        "Japanese": "オリジナル記事",
        "Arabic": "المقالة الأصلية",
        "Hebrew": "מאמר מקורי"
    },
    "read original article": {
        "English": "Read original article",
        "French": "Lire l'article original",
        "Spanish": "Leer el artículo original",
        "Hindi": "मौलिक लेख पढ़ें",
        "Chinese": "阅读原始文章",
        "Japanese": "オリジナル記事を読む",
        "Arabic": "اقرأ المقالة الأصلية",
        "Hebrew": "קרא את המאמר המקורי"
    },
    // Add other translations similarly
};

export default traductions;
