// Define the mapping of countries to country codes
export const countryMapping = {
    "France": "FR",
    "India": "IN",
    "Russia": "RU",
    "Ukraine": "UA",
    "Tunisia": "TN",
    "Morocco": "MA",
    "Algeria": "DZ",
    "Taiwan": "TW",
    "Mexico": "MX",
    "Singapore": "SG",
    "USA": "US",
    "Canada": "CA",
    "England": "GB",
    "Spain": "ES",
    "Japan": "JP",
    "Italy": "IT",
    "Korea": "KR",
    "Germany": "DE",
    "Brazil": "BR",
    "Saudi Arabia": "SA",
    "China": "CN",
    "Argentina": "AR",
    "Bolivia": "BO",
    "Chile": "CL",
    "Colombia": "CO",
    "Ecuador": "EC",
    "Peru": "PE",
    "Uruguay": "UY",
    "Venezuela": "VE",
    "Israel": "IL",
    "Lebanon": "LB",
    "Syria": "SY",
    "Qatar": "QA",
    "Egypt": "EG",
    "Iran": "IR",
};

// Define the countries array with labels only
export const countries = [
    { value: "France", label: "France" },
    { value: "India", label: "India" },
    { value: "Russia", label: "Russia" },
    { value: "Ukraine", label: "Ukraine" },
    { value: "Tunisia", label: "Tunisia" },
    { value: "Morocco", label: "Morocco" },
    { value: "Algeria", label: "Algeria" },
    { value: "Taiwan", label: "Taiwan" },
    { value: "Mexico", label: "Mexico" },
    { value: "Singapore", label: "Singapore" },
    { value: "USA", label: "USA" },
    { value: "Canada", label: "Canada" },
    { value: "England", label: "England" },
    { value: "Spain", label: "Spain" },
    { value: "Japan", label: "Japan" },
    { value: "Italy", label: "Italy" },
    { value: "Korea", label: "Korea" },
    { value: "Germany", label: "Germany" },
    { value: "Brazil", label: "Brazil" },
    { value: "Saudi Arabia", label: "Saudi Arabia" },
    { value: "China", label: "China" },
    { value: "Argentina", label: "Argentina" },
    { value: "Bolivia", label: "Bolivia" },
    { value: "Chile", label: "Chile" },
    { value: "Colombia", label: "Colombia" },
    { value: "Ecuador", label: "Ecuador" },
    { value: "Peru", label: "Peru" },
    { value: "Uruguay", label: "Uruguay" },
    { value: "Venezuela", label: "Venezuela" },
    { value: "Israel", label: "Israel" },
    { value: "Lebanon", label: "Lebanon" },
    { value: "Syria", label: "Syria" },
    { value: "Qatar", label: "Qatar" },
    { value: "Egypt", label: "Egypt" },
    { value: "Iran", label: "Iran" },
];
