import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Post from "../Post/Post";
import { HelmetProvider } from 'react-helmet-async';
import { categories } from '../Category/category';
import { faArrowTrendUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import FontAwesomeIcon

const TimeLinePost = ({ language, countries, selectedCategories }) => {
  // State variables
  const [allPosts, setAllPosts] = useState([]);
  const [timeLine, setTimeLine] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Latest news');
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [showCategories, setShowCategories] = useState(true);
  const [showKeywords, setShowKeywords] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [verifiedNewsOnly, setVerifiedNewsOnly] = useState(false);
  const [marginTop, setMarginTop] = useState('170px');


  
  let lastScrollY = 0;
  let additionalDataFetched = false;
  const selectedCategoryRef = useRef(null);
 
  useEffect(() => {
    if (selectedCategoryRef.current) {
      selectedCategoryRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [selectedCategory]);

  // Fetch data on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const countriesQueryParam = countries.join(',');
        const response = await axios.get(`https://collaborative-citizen-8dfb225eb320.herokuapp.com/api/posts/firsttimeline?language=${language}&country=${countriesQueryParam}`);
        setAllPosts(response.data);
        setInitialLoadComplete(true);
      } catch (error) {
        console.error("Failed to fetch timeline posts:", error);
      }
    };

    if (countries.length > 0) {
      fetchData();
    }
  }, [language, countries, selectedCategories]);

  useEffect(() => {
    const handleResize = () => {
      setMarginTop(window.innerWidth <= 444 ? '150px' : '170px');
    };
  
    window.addEventListener('resize', handleResize);
    handleResize(); // Appel initial pour définir la marge correcte
  
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch additional data on user interaction
  useEffect(() => {
    const fetchAdditionalData = async () => {
      try {
        const countriesQueryParam = countries.join(',');
        const response = await axios.get(`https://collaborative-citizen-8dfb225eb320.herokuapp.com/api/posts/timeline?language=${language}&country=${countriesQueryParam}`);
        setAllPosts(prevPosts => [...prevPosts, ...response.data]);
      } catch (error) {
        console.error("Failed to fetch additional data:", error);
      }
    };

    const handleUserInteraction = () => {
      if (initialLoadComplete && !additionalDataFetched) {
        fetchAdditionalData();
        additionalDataFetched = true; // Ensure it fetches only once
      }
    };

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setShowCategories(currentScrollY <= lastScrollY || currentScrollY < 100);
      lastScrollY = currentScrollY;
      handleUserInteraction();
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('touchstart', handleUserInteraction);
    window.addEventListener('mousemove', handleUserInteraction);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('touchstart', handleUserInteraction);
      window.removeEventListener('mousemove', handleUserInteraction);
    };
  }, [initialLoadComplete, language, countries, selectedCategories]);

  // Filter posts based on selected categories and keywords
  useEffect(() => {
    let filteredPosts;

    if (searchQuery.trim() !== '') {
      filteredPosts = allPosts.filter(post =>
        post.language === language &&
        countries.includes(post.country) &&
        post.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else if (showKeywords === 'Ukraine-Russia') {
      filteredPosts = allPosts.filter(post =>
        post.language === language &&
        countries.includes(post.country) &&
        ((post.title.includes('Ukraine')) ||
        (post.description && (post.description.includes('Ukraine') || post.description.includes('Russia'))))
      );
    } else if (showKeywords === 'Israel-Hamas') {
      filteredPosts = allPosts.filter(post =>
        post.language === language &&
        countries.includes(post.country) &&
        (post.title.includes('Israel') ||
        (post.description && (post.description.includes('Israel') || post.description.includes('Hamas'))))
      );
    } else if (showKeywords === 'Ramadan') {
      filteredPosts = allPosts.filter(post =>
        post.language === language &&
        countries.includes(post.country) &&
        (post.title.includes('Ramadan') ||
        (post.description && post.description.includes('Ramadan')))
      );
    } else {
      if (selectedCategories.includes('All')) {
        filteredPosts = allPosts.filter(post =>
        post.language === language &&
        countries.includes(post.country));
      } else {
        filteredPosts = allPosts.filter(post =>
          post.language === language &&
          countries.includes(post.country) &&
          selectedCategories.includes(post.tag2)
        );
      }
    }

    const uniqueFilteredPosts = filteredPosts.reduce((acc, current) => {
      const x = acc.find(item => item.title === current.title);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    setTimeLine(uniqueFilteredPosts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
  }, [allPosts, language, countries, selectedCategories, showKeywords, searchQuery]);

  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
    }
};

  // Handle category filter
  const handleCategoryFilter = (category) => {
    setSelectedCategory(category);
    setShowKeywords(false); // Reset keywords filter when category button is clicked
    setSearchQuery(''); // Reset search query when category button is clicked

    let filteredPosts;
    if (category === 'Latest news') {
      filteredPosts = allPosts.filter(post => post.language === language && countries.includes(post.country) && selectedCategories.includes(post.tag2));
    } else {
      filteredPosts = allPosts.filter(post => post.language === language && countries.includes(post.country) && category === post.tag2);
    }

    if (verifiedNewsOnly) {
      filteredPosts = filteredPosts.filter(post => post.FactCheck && post.FactCheck.factuality.toString() === 'true');
    }

    const uniqueFilteredPosts = filteredPosts.reduce((acc, current) => {
      const x = acc.find(item => item.title === current.title);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);




    scrollToTop();
    setTimeLine(uniqueFilteredPosts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
  };

  // Handle keyword filter
  const handleKeywordFilter = (keyword) => {
    setShowKeywords(prevKeyword => prevKeyword === keyword ? false : keyword);
    setSelectedCategory('Latest news');
  };

  // Handle search filter
  const handleSearchFilter = (query) => {
    setSearchQuery(query);
    setSelectedCategory('Latest news');
    setVerifiedNewsOnly(false);
  };

  const handleVerifiedToggle = () => {
    setSearchQuery('')
    setVerifiedNewsOnly(prev => {
      const newVerifiedNewsOnly = !prev;
  
      let filteredPosts;

      // Apply category filter
      if (selectedCategory === 'Latest news') {
        filteredPosts = allPosts.filter(post => post.language === language && countries.includes(post.country) && selectedCategories.includes(post.tag2));
      } else {
        filteredPosts = allPosts.filter(post => post.language === language && countries.includes(post.country) && selectedCategory === post.tag2);
      }
  
      // Apply verification filter if necessary
      if (newVerifiedNewsOnly) {
        filteredPosts = filteredPosts.filter(post => post.FactCheck && post.FactCheck.factuality);
      }
  

      const uniqueFilteredPosts = filteredPosts.reduce((acc, current) => {
        const x = acc.find(item => item.title === current.title);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
  
      setTimeLine(uniqueFilteredPosts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
  
      return newVerifiedNewsOnly;
    });
  };
  
  

  return (
    <div className="mt-5" style={{ backgroundColor: '#ffffff' }}>
      <HelmetProvider>
        <title>Explore Verified Timeline Posts: Stay Updated with Real-Time Fact-Checked News and Debunk Fake News</title>
        <meta name="description" content="View the latest timeline posts from trusted sources around the world. Explore verified news on international conflicts, fact-checked in real-time. Stay informed and avoid fake news." />
     </HelmetProvider>

      {/* Combined Filter */}
      {showCategories && (
        <div style={{ position: 'fixed', top: '75px', left: '0', width: '100vw', display: 'flex', flexDirection: 'column', gap: '10px', zIndex: '10', backgroundColor: '#ffffff', padding: '10px', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)' }}>
          {/* Category Filter */}
          <div style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll', gap: '10px', alignItems: 'center' }}>
            <>
              <button
                ref={selectedCategory === 'Latest news' ? selectedCategoryRef : null}
                onClick={() => handleCategoryFilter('Latest news')}
                style={{
                  backgroundColor: selectedCategory === 'Latest news' ? '#031f2d' : '#ffffff',
                  color: selectedCategory === 'Latest news' ? '#fff' : '#031f2d',
                  borderRadius: '9999px',
                  border: '1px solid #031f2d',
                  padding: '5px 10px',
                  fontSize: '16px',
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                  display: selectedCategories.includes('All') ? 'none' : 'inline-block'
                }}
              >
                Latest news
              </button>
              {categories.map((category, index) => (
                <button
                ref={selectedCategory === category.label ? selectedCategoryRef : null}
                  key={index}
                  onClick={() => handleCategoryFilter(category.label)}
                  style={{
                    backgroundColor: selectedCategory === category.label ? '#031f2d' : '#ffffff',
                    color: selectedCategory === category.label ? '#fff' : '#031f2d',
                    borderRadius: '9999px',
                    border: '1px solid #031f2d',
                    padding: '5px 10px',
                    fontSize: '16px',
                    cursor: 'pointer',
                    whiteSpace: 'nowrap',
                    display: selectedCategories.includes(category.label) ? 'inline-block' : 'none'
                  }}
                >
                  <FontAwesomeIcon icon={faArrowTrendUp} style={{ marginRight: '5px' }} />  {category.label}
                </button>
              ))}
            </>
          </div>
          {/* Search input and Verified toggle container */}
          <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px', width: '100%' }}>
  <input
    type="text"
    placeholder="Search by title..."
    value={searchQuery}
    onChange={(e) => handleSearchFilter(e.target.value)}
    style={{
      borderRadius: '9999px',
      border: '1px solid #031f2d',
      padding: '5px 10px',
      fontSize: '16px',
      marginRight: '10px',
      width: '50%'
    }}
  />

  <div className="switch-container" style={{ width: '50%', marginLeft: 'auto' }}>
    <label className="switch">
      <input
        type="checkbox"
        checked={verifiedNewsOnly}
        onChange={handleVerifiedToggle}
      />
      <span className={`slider round ${verifiedNewsOnly ? 'text-right' : 'text-left'}`}>
        {verifiedNewsOnly ? "Verified only" : "All factuality"}
      </span>
    </label>
  </div>
</div>


        </div>
      )}

      {/* Timeline Posts */}
      <div className="mt-15" style={{ marginTop }}>
        {timeLine.length > 0 ? (
          timeLine.map(post => (
            <div key={post._id} className="p-0">
              <Post key={language} post={post} language={language} />
            </div>
          ))
        ) : (
          <p style={{ fontStyle: 'italic', marginRight: '10px', marginLeft: '20px' }}>
            There are no posts to display with your current filters, please adjust them for more content <span style={{ fontStyle: 'normal' }}>😊</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default TimeLinePost;
