import React, { useContext, useState } from "react";
import { HelmetProvider } from 'react-helmet-async';
import { useDispatch } from "react-redux";
import { logout } from "../../redux/userSlice";
import FilterComponent from '../FilterComponent/FilterComponent';
import { languages } from "../Language/language";
import { countries } from "../Country/country";
import { categories } from "../Category/category";
import { ThemeContext } from "../../App";

const Footer = ({ onLanguageChange, onCountryChange, selectedCategories, setSelectedCategories,language: initialLanguage }) => {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [language, setLanguage] = useState(initialLanguage);
  const [selectedCountries, setSelectedCountries] = useState([]);

  const logo = theme === "light" ? "/logo.png" : "/logo-dark.png";

  const handleLogout = () => {
    dispatch(logout());
  };
  console.log("Language in Footer:", initialLanguage);
  return (
    <div className="footer-container">
<HelmetProvider>
<title>Explore Verified News: Footer Component for Filtering Real-Time Updates and Debunking Fake News</title>
  <meta name="description" content="Explore and filter news by country and language with our interactive footer component. Avoid fake news with reliable sources. Get accurate information on fact checking and truth verification." />
</HelmetProvider>


      <div className="fixed-bottom bg-transparent p-1">
        <FilterComponent
          theme={theme}
          selectedCountries={selectedCountries}
          setSelectedCountries={setSelectedCountries}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          language={initialLanguage}
          setLanguage={setLanguage}
          onLanguageChange={onLanguageChange}
          onCountryChange={onCountryChange}
          countries={countries}
          categories={categories}
          languages={languages}
        />
      </div>
    </div>
  );
};

export default Footer;
