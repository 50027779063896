import React, { useContext, useState } from "react";
import { HelmetProvider } from 'react-helmet-async'; // Import Helmet
import { useDispatch } from "react-redux";
import { ThemeContext } from "../../App";
import { logout } from "../../redux/userSlice";
import FilterComponent from '../FilterComponent/FilterComponent';
import { languages } from "../Language/language";
import { countries } from "../Country/country";

const Navbar = ({ onLanguageChange, onCountryChange }) => {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [language, setLanguage] = useState("English");

  const logo = theme === "light" ? "/logo.png" : "/logo-dark.png";

  const handleLogout = () => {
    dispatch(logout());
  };

  // Function to refresh the page
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div 
      className="fixed top-0 left-0 right-0 z-0 bg-[#031f2d] p-1 mb-2" 
      style={{ alignItems: 'flex-start' }}
      // Attach onClick event handler to trigger page refresh
      onClick={refreshPage}
    >
      {/* SEO adjustments */}
      <HelmetProvider>
      <title>Global Daily News: Fact Checker, Fake News Detection, and More</title>
  <meta name="description" content="Stay informed with your daily dose of local news from around the world. Get the latest updates and stay connected globally with our fact checker, ensuring real-time verification, unbiased reporting, and political fact checks." />
</HelmetProvider>

      <div className="flex justify-between items-center mb-2" style={{ marginTop: '10px' }}>
        {/* Left Column */}
        <div className="flex items-center">
          <div>
            <img src={logo} alt="Logo" className="ml-0" style={{ width: '50%', height: 'auto' }} />
            <p style={{ margin: '0', fontFamily: 'Arial', fontSize: '13px', color: '#fff', letterSpacing: '1px' }}>The 1<sup>st</sup> choice for complex global topics.</p>
          </div>
        </div>
  
        {/* Right Column */}
        <div>
          <p style={{ margin: '0', fontFamily: 'Arial',fontSize: '15px', fontWeight: 'bold', color: '#fff', textAlign: 'right', marginRight: '10px' }}>
            {new Date().toLocaleDateString('en-US', { weekday: 'long' })}
          </p>
          <p style={{ margin: '0', fontStyle: 'italic', fontSize: '13px' , fontFamily: 'Arial', color: '#fff', textAlign: 'right', marginRight: '10px' }}>
            {new Date().toLocaleDateString()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
